.right-button {
	.ant-btn {
		background: #7e27fc;
		color: #fff;
		position: absolute;
		right: 0;
		top: 0;
		&:hover {
			background: #1DA57A;
			color: #fff;
		}
	}
}
.logo {
	> a {
		position: relative;

		> .ant-image {
			
			+ span {
				visibility: visible;
			}
		}

		> span {
			position: absolute;
			visibility: hidden;
			display: block;
			right: 32px;
			bottom: -5px;
			color: #19d3a9;
			font-size: 12px;
		}
	}
}
.ant-layout-header {
	.wrapper {
		position: relative;
		display: flex;
		align-items: center;
		flex-flow: row wrap;
		justify-content: space-between;

		.desk-nav {
			ul {
				display: flex;
				flex-flow: row wrap;
				padding: 0;
				margin: 0;
				li {
					position: relative;
					list-style: none;
					margin: 0 15px;
					&:last-child {
						margin-right: 0px;
					}
					&:first-child {
						margin-left: 0px;
					}

					a {
						color: #fff;
						font-size: 16px;
						font-weight: 600;

						&:hover {
							color: #7e27fc;
						}
					}

					&::after {
						content: "";
						position: absolute;
						width: 1px;
						height: 75%;
						background: #e0e0e0;
						right: -15px;
						transform: translateY(-50%);
						top: 50%;
					}

					&:last-child::after {
						display: none;
					}
				}
			}
		}

		.hide {display: none;}
	}
}

.toggle-mode {
	position: relative;
	cursor: pointer;
	margin-left: 20px;
}
.ToggleSwitch {
	background: linear-gradient(#ccc, #eee);
	border: 1px solid #151236;
	height: 2em;
	width: 5em;
	border-radius: 1em;
  }
  .ToggleSwitch.active {
	background: #151236;
  }
  .ToggleSwitch .knob {
	position: relative;
	width: 1.9em;
	height: 1.9em;
	background: #151236;
	border: 1px solid #151236;
	border-radius: 50%;
	left: -5px;
    top: 50%;
    transform: translateY(-50%);
	transition: left 0.3s ease-out;
  }
  .ToggleSwitch .knob.active {
	left: 3em;
	background: rgb(49, 0, 114);
	border: 1px solid rgb(49, 0, 114);
  }
  .ToggleSwitch .knob::after {
	  content: "";
	  position: absolute;
	  width: 14px;
	  height: 14px;
	  background: url(../assets/images/sun.svg) no-repeat;
	  background-size: contain;
	  background-position: center;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
  }
  .ToggleSwitch .knob.active::after {
	background: url(../assets/images/moon.svg) no-repeat;
	background-size: contain;
	background-position: center;
  }

.sidemenu{
	-webkit-transform: translateX(100%);
	-webkit-transition: -webkit-transform .3s ease-in-out;
	background-color: #fff;
	height: 100vh;
	right: 0;
	max-width: 420px;
	overflow: hidden;
	padding: 0;
	position: fixed;
	top: 0;
	transform: translateX(100%);
	transition: -webkit-transform .3s ease-in-out;
	transition:transform .3s ease-in-out;
	transition:transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
	width: 100vw;
	z-index: 9999;
}
.sidebar-open {
	.sidemenu {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

.close-menuicon {
    border: 1px solid #c1c1c1 !important;
    color: #c1c1c1;
	position:absolute;
	right: 10px;
	top:10px;
    opacity: 0;
	transition-delay: 0.2s;
	transition-duration: 0.3s;
	transition-property: opacity;
	&:hover {
		border-color:#1da57a !important;
		color: #1da57a;
	}
}
.sidebar-open {
	.close-menuicon {
		opacity: 1;
	}
}

.pricing {
	.pricing-new {
		ul {
			li {
				&.mob-link {display: none;}
			}
		}
	}
}

.ant-select {
	&.search-int {
		margin: 35px auto 25px;
		max-width: 260px;

		.ant-select-selector {
			background-color: transparent;
			text-align: left;

			input {
				color: #fff;
			}

			.ant-select-selection-item {
				.drp-flx {
					display: flex;
					flex-flow: row wrap;
					align-items: center;
			
					span {
						font-size: 14px;
						font-weight: 400;
						margin: 0 0 0 5px;
						color: #fff;
					}
			
					.ant-image {
						img {
							width: 20px;
							height: 20px;
							border-radius: 50%;
						}
					}
				}
			}
		}

		.ant-select-arrow {
			color: #bfbfbf;
		}
	}
}
.ant-select-item-option-content {
	.drp-flx {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		margin: 3px 0;

		span {
			font-size: 14px;
			font-weight: 600;
			margin: 0 0 0 5px;
		}

		.ant-image {
			img {
				width: 30px;
				height: 30px;
				border-radius: 50%;
			}
		}
	}
}
.drp-flx {
	&.no-data {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		margin: 3px 0;

		span {
			font-size: 14px;
			font-weight: 600;
			color: rgba(0,0,0,.85);
			margin: 0 0 0 5px;
		}

		.ant-image {
			img {
				width: 30px;
				height: 30px;
				border-radius: 50%;
			}
		}
	}
}
.spin {
	display: flex;
	justify-content: center;
	align-items: center;
}
#mrp-box {
	&.desk-sech {
		input {
			color: #000;
		}
	}
}
html {
	&.dark {
		#mrp-box {
			&.desk-sech {
				input {
					color: #BDBDBD;
				}
			}
		}
		.drp-flx {
			&.no-data {
				span {
					color: #fcfcff;
				}
			}
		}

	}
}
#drp-box {
	.ant-select {
		color: #fcfcff;
	}
}
.desk-sech {
	position: relative;
	@media (max-width: 1000px) {
		display: none;
	}
	.ant-select.search-int {
		max-width: 480px;
		width: 100vw !important;
		background: #a0a0a0;
		border-radius: 30px;
		padding: 2px;
		margin: 0 auto;

		@media (max-width: 1420px) {
			max-width: 380px;
		}
		@media (max-width: 1260px) {
			max-width: 300px;
		}
		@media (max-width: 1080px) {
			max-width: 280px;
		}

		&.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
			.ant-select-selector {
				border-color: #d9d9d9;
				box-shadow: none;
				border-right-width: 0px !important;
			}
		}

		.ant-select-selector {
			height: 38px;
			align-items: center;
			border-radius: 30px;
			padding: 3px 11px 3px 35px;
			background: #151236;
			border-width: 0px !important;
			// border-color: #929292;
			

			.ant-select-selection-search {
				display: flex;
				align-items: center;
			}

			.ant-select-selection-item {
				height: 100%;
			}

			input {
				padding: 0 0 0 25px !important;
			}
		}
	}
	.anticon {
		position: absolute;
		display: inline-block;
		top: 50%;
		transform: translateY(-50%);
		left: 10px;
		svg {
			font-size: 18px;
			color: #fff;
		}
	}
}
.mob-search {
	display: block;
	max-width: 380px;

	@media (max-width: 500px) {
		width: 100%;
	}
	margin: 0 auto;
	.desk-sech {
	  display: none;
	}
	
	@media (max-width: 1000px) {
	  .desk-sech {
		display: block;
		margin: 10px 0 25px;

		.ant-select.search-int {
			max-width: 100%;
			margin: 0 auto;

			.ant-select-selector {
				background: #fff;

				.ant-select-selection-item {
					.drp-flx {
						span {
							color: rgba(0, 0, 0, 0.85);
						}
					}
				}
			}
		}
		.anticon {
			svg {
				color: #7e27fc;
			}
		}
	  }
	}
}

@media (max-width: 1000px){
.close-menuicon {
	.anticon-close {
		background: none;
		height: auto;
		width: auto;
	}
}
.right-button {
	.ant-btn {
		right: 80px;
	}
} 
.pricing {
	.pricing-new {
		ul {
			li {
				&.mob-link {
					position: relative;
					display: block;
					font-size: 14px;
					margin-bottom: 3px;
					padding: 0 0 5px 0;

					a {
						font-size: 14px;
						margin: 0 5px 5px;
						color: #fff;
					}

					&.sap {
						margin-top: 20px;
						&::before {
							content: "";
							top: -10px;
							left: 0;
							right: 0;
							position: absolute;
							width: 82%;
							height: 1px;
							background: #5f5a8a;
							margin: 0 auto;
						}
					}
				}

				&:last-child {
					margin-bottom: 25px;
				}
			}
		}
	}
}   

.ant-drawer-header {
	&.ant-drawer-header-close-only {
		padding-top: 0;
	}
}
.ant-drawer-content-wrapper {
	.ant-drawer-content {
		.ant-drawer-body {
			> div {
				> div {
					position: relative !important;
					height: 100% !important;
					transition: all .5s ease-in-out;
					z-index: inherit;
				}
			}
			.ant-anchor-wrapper {
				overflow: inherit;
				max-height: 100vh !important;
			}
		}
	}
}

}


@media (max-width: 480px){
.sidemenu {
	display: none;
    transform: translateX(0);
    transform: translateY(-100%);
    max-width: 100%;
}
.sidebar-open .sidemenu {
    transform: translateX(0);
    transform: translateY(0);
}
    .mobile-view-nav{
        right: 0;
    }    
.right-button {
	.ant-btn {
		right: 50px;
	}
}
.close-menuicon {
	opacity: 0;
	transition-delay: 0.5s;
	transition-duration: 0.5s;
	transition-property: opacity;
}

}

/*** Disconnect Button ***/
button.disconnect-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #7e27fc;
    border: 1px solid #7e27fc;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    height: 28px;
    border-radius: 22px;
    padding: 4px 18px;
    margin-left: 10px;
    &:hover {
        background: #FFF;
        border: 1px solid #FFF;
        color: #7e27fc;
    }
}
html.dark button.disconnect-btn {
    &:hover
    {
        background: #252e33;
        color: #ffffff;
        border: 1px solid #495358;
    }
}
@media (max-width: 1000px){
    .dis_btn_holder {
        width: 100%;
    }
}
/*** Disconnect Button END ***/

/**** warning popup ****/
.ant-modal.solo-connect-popup {
	.ant-modal-content {
		background-color: #242146;
        border-radius: 16px;
        text-align: center;
        width: 420px;
        margin: 0 auto;
        
        article{
            padding: 30px 20px 20px;
        }
        button.ant-btn{
            background: #1da57a;
            margin: 20px 0 0;
            padding: 8px 40px;
            height: auto;
            min-width: 180px;
            border-radius: 30px;
            transition: all 0.4s ease;
            font-size: 16px;
            font-weight: bold;
            color: #000;
        }
        button.ant-btn:hover{
            background: #28c6bd;
        }
	}
    ::after {
        position: fixed;
        content: '';
        width: 400%;
        height: 400%;
        left: -100%;
        top: -100%;
        z-index: -1;
        background: #0e0c20;
    }
    .ant-typography{
        color: #dcdaf0;
        font-size: 16px;
    }
    .ant-alert{
        position: absolute;
        width: 96%;
        left: 2%;
        top: calc(100% + 12px);
        border-radius: 10px;
        border: none;
        text-align: left;
    }
    .ant-alert.ant-alert-warning, .ant-alert-message{
        background: #321515;
        color: #eca3a3;
    }
    .ant-alert.ant-alert-success{
        background: #20313b;
        color: #a3d1ec;
    }
    .ant-alert-warning .ant-alert-icon {
        color: #e65252;
    }
    .ant-alert-success .ant-alert-icon {
        color: #58a4d0;
    }
    .ant-modal-footer, .ant-modal-close{
        display: none;
    }
}

@media (max-width: 480px){
    .ant-modal.solo-connect-popup {
	   .ant-modal-content {
            border-radius: 15px;
            width: 94%;
           .ant-image{
               width: 90%;
           }
            article {
                padding: 15px 0px 15px;
           }
           .ant-alert.ant-alert-warning{
               padding: 12px;
           }
        }
    }
    
}
/**** warning popup END ****/



@primary-color: #1DA57A;