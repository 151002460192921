.rarities-table{
	// display:flex;
	// justify-content:flex-start;
	// flex-wrap: wrap;
}

.rarities-table .rarities-head {
	// width: 31%;
    margin-right: 20px;
	margin-bottom: 15px;
}


.rarities-table .rarities-head:nth-child(3n){
	margin-right:0px;
}
.rarities-table .rarities-head .ant-table-tbody tr td {white-space: nowrap;}
.rarities-table .rarities-head .ant-table-tbody tr td .rari-split {
	flex-wrap: nowrap;
	align-items: center;
}
.rarities-table .rarities-head .ant-table-tbody tr td span {
	max-width: calc(100% - 36px);
}

.token-panel{
	border-radius: .5rem;
	background:#fff;
border-width: 1px;
padding: 1rem;
--tw-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);
box-shadow: var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
position: relative;
margin-bottom:10px
}

.token-text{
	line-height: 1.75rem;
	font-size: 1.25rem;
}

.token-text strong{
	font-weight: bolder;
}

.mb-20{
	margin-bottom:20px
}

@media(max-width:1080px){
.rarities-table .rarities-head {
	// width: 48%;
    margin-right: 20px;
    margin-top:20px
}	
.rarities-table .ant-table-content{
	display:block!important
}
.rarities-table .rarities-head:nth-child(2n){
	margin-right:0px;
}
}

@media(max-width:780px){
.rarities-table .rarities-head {
	width: 100%;
	margin-right: 0px;
}	
} 
@primary-color: #1DA57A;