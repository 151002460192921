body {
    max-width: 100%;
    overflow-x: hidden;
}

.chart-row {
    .chart-innr {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 25px;
        background-image: url(../assets/images/monkey-logo.png);
        background-repeat: no-repeat;
        background-position: 80% 5%;
        background-size: 200px;
        background-color: #fff;
        box-shadow: 9px 1px 9px 0px rgb(20 18 32 / 2%);
        border-radius: 10px;
        margin: 15px;
        max-width: 100%;
        height: calc(100% - 30px);
        overflow-x: hidden;

        &.chart-nobk {
            background-size: 0px;
        }
    
        canvas {
            background-image: url(../assets/images/solog-logo.png);
            background-size: 450px;
            background-position: 50%;
            background-repeat: no-repeat;
            max-height: 400px;
            padding: 2px 5px 0;
        }
 
        .ant-tabs-tabpane {
            opacity: 0;
            visibility: hidden;
            &.ant-tabs-tabpane-active {
                opacity: 1;
                visibility: visible;
            }
        }

        .nw-tab {
            canvas {
                background-color: #f8f8f8;
                // background-position: 97% 0%;
                // background-size: 100px;
            } 
            .chart-agg {
                canvas {
                    padding: 5px;
                }
            }
            
        }

        .chart-srt {
            height: 100%;
            min-height: 400px;

            &.srt-load {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .ant-spin-spinning {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
            > div {
                > div {
                    min-height: 400px;
                }
            }
            // > div {
            //     overflow-x: auto;
            // }
        }
        .chart-srtTxt {
            .chart-srt {
                padding: 0 0 10px 35px;
                height: auto;
            }
            .chart-horz {
                position: absolute;
                transform: rotate(-90deg);
                bottom: 145px;
                left: -20px;
                font-weight: 600;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
                font-size: 12px;
                color: #949494;
            }
            .chart-vert {
                position: relative;
                font-weight: 600;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
                font-size: 12px;
                color: #949494;
                left: 75px;
                margin: 5px 0 0;
            }

            &.chart-sct {
                .chart-srt {
                    padding: 0;
                }
            }
            &.chart-scat {
                canvas {
                    padding-bottom: 5px;
                }
            }
        }
        .chart-dis {
            margin: 5px 0 0;
            font-size: 12px;
            span {
                color: red;
            }
        }
        .chart-head {
            margin: 0 0 10px;
            .ant-input-search {
                > .ant-input-group {
                    > .ant-input-group-addon {
                        &:last-child {
                            left: 0;
                            padding-left: 15px;
                        }
                        .ant-btn {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #714af5;
                            padding: 0.5rem 1rem;
                            font-weight: 600;
                            text-transform: uppercase;
                            width: auto;
                            white-space: nowrap;
                            border-radius: 0.5rem;
                            color: #fff;
                            margin: 0;
                            transition: all .3s ease-in-out;

                            &:hover {
                                background: #210092;
                                transition: all .3s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
        .chart-body {
            display: flex;
            flex-direction: column;
            height: 100%;
            p {
                margin: 0 0 10px;
                font-style: italic;
                strong {
                    font-size: 17px;
                }
            }
            .txt-sml {
                font-style: italic;
                font-size: 12px;
                color: #727272;
                margin: 0 0 5px;
            }
            textarea {
                &.ant-input {
                    height: 100%;
                    max-height: 200px;
                }
            }
        }

        .chart-title {
            font-size: 17px;
            font-weight: 600;
            color: #412187;
            text-transform: uppercase;
            margin: 0 0 5px;
        }

        .ant-table-content {
            table {
                .ant-table-thead {
                    > tr {
                        .ant-table-cell {
                            text-align: left;
                        }
                    }
                }
                .ant-table-tbody {
                    > tr {
                        .ant-table-cell {
                            text-align: left;
                        }
                    }
                }
            }
        }

        &.main-chart {
            .chart-txt {
                margin: 0 0 75px; 
            }

            .chart-tab {

                .chart-srt  {
                    position: relative;
                    padding: 0 25px;
                    
                    &.chart-cnt {
                        p {
                            position: absolute;
                            font-weight: 600;
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
                            font-size: 12px;
                            margin: 0;
                            color: #949494;

                            &.cnt-left {
                                left: -50px;
                                top: 50%;
                                transform: translateY(-50%) rotate(-90deg);
                            }
                            &.cnt-right {
                                right: -50px;
                                top: 50%; 
                                width:120px;
                                transform: translateY(-50%) rotate(-90deg);
                            }
                        }
                    }
                }
            }
        }

        &.single-chart {
            max-width: 100%;
            overflow-x: hidden;

            .chart-txt {
                margin-bottom: 80px;
            }
        }

        .chart-srtTxt {
            &.chart-sct {
                margin-top: auto;
            }
        }

        &.left {
            &.chart-dist {
                canvas {
                    padding: 0 5px;
                }
            }
            canvas {
                background-color: #f8f8f8;
                // background-position: 97% 15%;
                // background-size: 100px;
            }
        }
        &.chart-dist {
            background-position: 75% 5%;
            overflow-y: hidden;
        }
    }
    &.no-rev {
        .chart-innr {
            &.chart-scatter {
                background-position: 95% 5%;
                .chart-txt {
                    margin-bottom: 75px;
                    h5 {
                        // margin-bottom: 75px;
                    }
                }
            }
        }
    }
    .chart-tab {
        &.nw-pop {
            .chart-slt {
                    position: relative;
                    text-align: right;
                    margin: 10px 0 0;
                    > span {
                        display: inline-block;
                        font-weight: 600;
                        margin: 0 5px 0 0;
                    }
                .ant-select {
    
                    .ant-select-selector {
                            display: flex;
                            align-items: center;
                            padding: 5px;
                            background: #fff;
                            border: 1px solid #b2b2b2;
                            border-radius: 7px;
                            min-height: auto;
                            height: 33px;
    
                        .ant-select-selection-item {
                            padding: 0 6px 0 0;
                            text-align: center;
                        }
                    }
                    .ant-select-arrow {
                        right: 7px;
                    }
                }
            }
        }
        .market-val {
            display: flex;
            flex-flow: row wrap;
            margin: 10px 0;
            .market-val-item {
                flex: 0 1 auto;
                max-width: 50%;
                display: flex;
                flex-flow: row wrap;
                .market-item {
                    flex: 0 1 auto;
                    max-width: 50%;
                    margin: 0 60px 0 10px;
                    h6 {
                        position: relative;
                        color: #5c5e7b;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 21px;
    
                        &.no-color {
                            span {
                                &.market-color-box {
                                    background: #9c9c9c;
                                }
                            }
                        }
    
                        span {
                            &.mar-title {
                                position: relative;
                                padding: 0 0 0 17px;
                                white-space: nowrap;
    
                                .market-color-box {
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }
                            &.market-color-box {
                                position: absolute;
                                top: 0;
                                left: 0;
                                display: inline-block;
                                width: 13px;
                                height: 13px;
                                margin-right: 3px;
                                background: transparent;
                                border-radius: 2px;
    
                                &.max {
                                    background: #4bc45c;
                                }
                                &.min {
                                    background: #e43434;
                                }
                                &.price {
                                    background: #7e27fc;
                                }
                                &.avg {
                                    background: #2775e8;
                                }
                            }
                            &.market-txt {
                                display: block;
                                color: #5c5e7b;
                                width: 100%;
                                text-align: left;
                                font-weight: 700;
                                padding: 0 0 0 17px;
                            }
                        }
                    }
                }
                &:first-child {
                    .market-item {
                        &:first-child {
                            margin-left: 0px;
                        }
                    }
                }
                &:last-child {
                    .market-item {
                        &:last-child {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
    }

    &.rev-row {
        .chart-innr {
            &.chart-scatter {
                .chart-txt {
                    margin: 0 0 75px;
                }
            }
        }
        .ant-row {
            .ant-col {
                // transition: width .5s ease-in-out;
                &.chart-rev-full {
                    order: 3;
                }
                &.chart-rev-innr {
                    order: 2;
                }
                .chart-tab {
                    .market-val {
                        flex-flow: column wrap;
                        .market-val-item {
                            max-width: 100%;
                            .market-item {
                                min-width: 160px;
                                &.full {

                                }
                            }
                            &:first-child {
                                margin-bottom: 15px;
                            }
                            &:last-child {
                                .market-item {
                                    &:first-child {
                                        margin-left: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
                .chart-innr {
                    background-position: 93% 80px;
                    background-size: 165px;
                    &.chart-dist {
                        background-position: 7% 13%;
                        .chart-txt {
                            margin: 0 0 15px;
                        }
                    }
                    canvas {
                        background-size: 80%;
                    }
                    &.chart-nobk {
                        background-size: 0px;
                    }
                }
            }
        }
    }

    .ant-row {
        &.rev-cen {
            justify-content: center;
        }

        &.load-rev {
            justify-content: center;
        }
    }

    &.dis-tab {
        .chart-tab {
            .ant-tabs-nav {
                .ant-tabs-nav-wrap {
                    .ant-tabs-nav-list {
                        .ant-tabs-tab {
                            &.ant-tabs-tab-active {
                                pointer-events: auto;
                                z-index: 5;
                            }
                            pointer-events: none;
                            user-select: none;
                            z-index: -100;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: rgb(240 242 245 / 50%);
                            pointer-events: none;
                            user-select: none;
                            z-index: 1;
                        }
                    }
                }
    
            }
        }
    }
}
.chart-txt {
    display: flex;
    flex-flow: row wrap;
    margin: 0 0 5px;
    h5 {
        font-size: 17px;
        font-weight: 600;
        color: #412187;
        text-transform: uppercase;
        line-height: 21px;
        margin: 0;

        span {
            color: #000;
            font-weight: 400;
            text-transform: initial;
        }
    }
    h3 {
        font-size: calc(18px + (25 - 18)*((100vw - 360px)/(1920 - 360)));
        font-weight: 400;
        margin: 0;

        span {
            font-weight: 700;
            margin-right: 5px;
        }
    }
    h4 {
        span {
            &.bold {
                font-weight: bold;
            }
        }
    }
    .chart-left {
        flex: 1 1 auto;
        max-width: 50%;
        display: flex;
        align-items: center;
        min-height: 42px;
    }

    &.mg-10 {
        margin: 0 0 5px;
    }
    &.chart-btm {
        margin: 15px 0 0;
        h4 {
            display: block;
            width: 100%;
        }
    }
}
.chart-tab {
    height: 100%;
    .ant-tabs {
        height: 100%;
        &.ant-tabs-top {
           > .ant-tabs-nav {
                position: absolute;
                top: 25px;
                right: 25px;
                font-weight: 600;

                &::before {
                    border-bottom: 0px;
                }

                .ant-tabs-nav-wrap {
                    &::after {}
                    .ant-tabs-tab {
                        margin: 0 0 0 15px;
                        padding-bottom: 5px;
                    }
                    &.ant-tabs-tab-active {

                    }
                }
            }
        }
        .ant-tabs-ink-bar {
            background: #411888;
        }
        .ant-tabs-content-holder {
            margin-top: auto;
            .ant-tabs-content {
                height: 100%;
            }
        }
    }
    &.nw-tab {
        .ant-tabs {
            height: 100%;
            &.ant-tabs-top {
               > .ant-tabs-nav {
                    background: #eee;
                    padding: 3px 5px;
                    border-radius: 8px;
    
                    .ant-tabs-nav-wrap {
                        &::after {}
                        .ant-tabs-nav-list {
                            margin: 2px 0;
                            .ant-tabs-tab {
                                margin: 1px 2px;
                                padding: 4px 13px;
                                background: transparent;
                                border-radius: 4px;
    
                                &.ant-tabs-tab-active {
                                    .ant-tabs-tab-btn {
                                        color: #7e27fc;
                                    }
                                }
                                &.ant-tabs-tab-disabled {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
            .ant-tabs-ink-bar {
                background: #ffffff;
                height: 100%;
                z-index: -1;
                border-radius: 4px;
                box-shadow: 1px 1px 3px 0px rgb(0 0 0 / 8%);
            }
        }
    }
    &.nw-dtab {
        .ant-tabs {
            height: 100%;
            &.ant-tabs-top {
               > .ant-tabs-nav {
                    background: #eee;
                    padding: 3px 5px;
                    border-radius: 8px;
    
                    .ant-tabs-nav-wrap {
                        &::after {}
                        .ant-tabs-nav-list {
                            margin: 2px 0;
                            .ant-tabs-tab {
                                user-select: none;
                                margin: 1px 2px;
                                padding: 4px 6px;
                                background: transparent;
                                border-radius: 4px;
    
                                &.ant-tabs-tab-active {
                                    .ant-tabs-tab-btn {
                                        color: #7e27fc;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .ant-tabs-ink-bar {
                background: #ffffff;
                height: 100%;
                z-index: -1;
                border-radius: 4px;
                box-shadow: 1px 1px 3px 0px rgb(0 0 0 / 8%);
            }

            .ant-tabs-tabpane {
                .chart-srt {display: none;}
                &.ant-tabs-tabpane-active {
                    .chart-srt {
                        display: block;
                        > div {
                            > div {
                                display: flex;
                                canvas {
                                    margin-top: auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        .ant-tabs-content-holder {
            padding: 15px 0 0;
        }
    }

    &.dis-tab {
        .ant-tabs-nav {
            .ant-tabs-nav-wrap {
                .ant-tabs-nav-list {
                    .ant-tabs-tab {
                        &.ant-tabs-tab-active {
                            pointer-events: auto;
                            z-index: 5;
                        }
                        pointer-events: none;
                        user-select: none;
                        z-index: -100;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgb(240 242 245 / 50%);
                        pointer-events: none;
                        user-select: none;
                        z-index: 1;
                    }
                }
            }
        }  
    }
}
.chart-sct {
    .g2-tooltip {
        margin-top: 25px;
    }
}
.g2-tooltip {

    border-radius: 5px;
    background-color: rgb(37, 37, 37) !important;
    .g2-tooltip-title {
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 8px;
    }
    .chart-tooltip {

        padding: 10px 2px;
        text-align: left;
        h2 {
            font-size: 14px;
            font-weight: 700;
            margin: 0 0 8px;
        }
        > div {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            p {
                line-height: 0;
                margin: 0;
            }
            span {
                display: inline-block;
                width: 13px;
                height: 13px;
                border: 1px solid #fff;
                border-radius: 2px;
                margin: 0 5px 0 0;
            }
        }

        .chart-val {
            flex-flow: row wrap;
            align-items: flex-start;

            p {
                display: block;
                font-size: 14px;
                line-height: 1.3;
                margin: 0 0 2px;
            }

            .chart-img {
                flex: 1 1 auto;
                max-width: 80px;
                min-width: 60px;
                display: block;
                width: auto;
                height: auto;
                border: none;
                border-radius: 0px;
                margin: 0 0 5px;
                img {
                    border: 1px solid #fff;
                    border-radius: 2px;
                    max-width: 80px;
                    max-height: 60px;
                    margin: 0 5px;
                }
            }
            .chart-cnt {
                flex: 1 1 auto;
                max-width: calc(100% - 80px);
                padding: 2px 2px 2px;

                .buy-btn {
                    display: inline-block;
                    background: #7e27fc;
                    font-weight: 600;
                    padding: 3px 10px;
                    border: 1px solid #7e27fc;
                    border-radius: 3px;
                    margin: 2px 0 0;
                    transition: all .3s ease-in-out;

                    &:hover {
                        color: #7e27fc;
                        background: #fff;
                        transition: all .3s ease-in-out;
                    }
                }
            }
        }
    }

    * {
        color: #fff;
    }
    .g2-tooltip-list {
        .g2-tooltip-list-item {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            .g2-tooltip-marker {
                width: 13px !important;
                height: 13px !important;
                border: 1px solid #fff;
                border-radius: 2px !important;
                margin-right: 5px !important;
            }
            .g2-tooltip-value {
                margin-left: 5px !important;
            }
        }
        .g2-tooltip-name {
            text-transform: capitalize;
        }
    }
}

.chart-popup {
    .ant-modal {
        .ant-modal-body {
            .ant-row {
                .ant-col {
                    > div {
                        overflow: hidden;
                        > div {
                            background-image: url(../assets/images/solog-logo.png);
                            background-position: 50% 50%;
                            background-repeat: no-repeat;
                            background-size: 450px;
                            background-color: #f8f8f8;

                            @media (max-width: 1378px) {
                                background-size: 350px;
                            }
                        }
                    }
                }
            }
        }
        canvas {
            padding: 0 5px;
            max-height: 400px;
        }
        .ant-modal-footer {
            display: none;
        }
    }
}


@media (min-width: 1921px) {

    .chart-txt {
        h3 {
            font-size: 25px;
        }
    }

}

@media (max-width: 1750px) {

    .chart-row {
        .chart-innr {
            background-position: 75% 5%;

            &.chart-dist {
                background-position: 70% 5%;
            }
        }
    }

}

@media (max-width: 1500px) {

    .chart-txt {
        h5 {
            span {
                display: block;
            }
        }
    }
    .chart-row {
        .chart-innr {
            &.chart-dist {
                background-position: 65% 5%;
            }
        }
    }

}
@media (max-width: 1400px) {
    .chart-txt {
        h5 {
           font-size: 16px;
        }
    }
    .chart-tab {
        &.nw-dtab {
            .ant-tabs {
                &.ant-tabs-top {
                    > .ant-tabs-nav {
                        .ant-tabs-nav-wrap {
                            .ant-tabs-nav-list {
                                .ant-tabs-tab {
                                    padding: 4px 3px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .chart-row {
        .chart-innr {
            background-size: 150px;
            background-position: 95% 14%;
        }
        &.rev-row {
            .ant-row {
                .ant-col {
                    .chart-innr {
                        &.chart-dist {
                            background-position: 7% 13%;
                            .chart-txt {
                                margin: 0 0 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1340px) {
    .chart-txt {
        .chart-left {
            max-width: 40%;
        }
    }
}

@media (max-width: 1280px) {
    .chart-txt {
        .chart-left {
            max-width: 100%;
            h5 {
                span {
                    display: inline-block;
                }
            }
        }
        margin: 0 0 10px;
    }
    .chart-row {
        .chart-tab {
            &.nw-pop {
                .chart-slt {
                    position: absolute;
                    top: 25px;
                    right: 25px;
                    margin: 0;

                    .ant-select {

                        .ant-select-selector {
                            padding: 5px 1px 5px 2px;

                            .ant-select-selection-item {
                                padding: 0 12px 0 0;
                            }
                        }
                    }
                }
            }
        }

        .chart-innr {
            &.chart-dist {
                background-position: 95% 13%;
            }
        }
        &.rev-row {
            .ant-row {
                .ant-col {
                    .chart-innr {
                        &.chart-dist {
                            background-position: 50% 11%;
                            .chart-txt {
                                margin-bottom: 60px;
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-select-item-option {
        padding: 5px;
        font-size: 14px;
    }
    .chart-tab {
        .ant-tabs {
            &.ant-tabs-top {
                > .ant-tabs-nav {
                    position: relative;
                    top: 0;
                    right: 0;
                    margin: 15px 0 15px;

                    .ant-tabs-nav-wrap {
                        .ant-tabs-tab {
                            &:first-child {
                                margin: 0 0 0;
                            }
                        }
                    }
                }
            }
        }

        &.nw-tab {
            .ant-tabs.ant-tabs-top {
                > .ant-tabs-nav {
                    position: absolute;
                    top: 25px;
                    right: 25px;
                    margin: 0;
                }
            }
        }
    }
    .chart-row {
        .chart-tab {
            .market-val {
                .market-item {
                    margin: 0 25px 0 10px;
                }
            }
        }
    }

    .chart-tab {
        &.nw-dtab {
            .ant-tabs.ant-tabs-top {
                > .ant-tabs-nav {
                    right: inherit;
                    background: transparent;
                    padding: 0;

                    &::before {
                        display: none;
                    }
                    .ant-tabs-nav-wrap {
                        justify-content: center;

                        .ant-tabs-nav-list {
                            background: #eee;
                            padding: 5px 6px;
                            border-radius: 8px;
                        }
                    }
                }
            }
            .ant-tabs {
                .ant-tabs-ink-bar {
                    height: calc(100% - 7px);
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

@media (max-width: 1180px) {

    .chart-row {
        &.rev-row {
            .ant-row {
                .ant-col {
                    .chart-tab {
                        .market-val {
                            .market-val-item {
                                .market-item {
                                    flex: 1 1 auto;
                                    min-width: auto;
                                    width: calc(100vw - 60px);
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .chart-tab {
            &.nw-pop {
                
            }
        }
    }
    .chart-tab {
        &.nw-dtab {
            .ant-tabs {
                &.ant-tabs-top {
                    > .ant-tabs-nav {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    .chart-row {
        // .chart-innr {
        //     background-size: 0px;
        //     &.main-chart {
        //         .chart-txt {
        //             margin: 0 0 0;
        //         }
        //     }
        // }
        // &.no-rev {
        //     .chart-innr {
        //         &.chart-scatter {
        //             padding: 25px;
        //         }
        //     }
        // }
        // &.rev-row {
        //     .ant-row {
        //         .ant-col {
        //             .chart-innr {
        //                 background-size: 0px;
        //                 &.chart-dist {
        //                     margin-bottom: 0px;

        //                     .chart-txt {
        //                         margin-bottom: 0px;
        //                     }
        //                 }

        //                 &.single-chart {
        //                     .chart-txt {
        //                         margin-bottom: 0px;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
    }
    
}

@media (max-width: 1080px) {

    .chart-row {
        &.rev-row {
            .ant-row {
                .ant-col {
                    .chart-tab {
                        .market-val {
                            .market-val-item {
                                flex: 1 1 auto;
                                max-width: 100%;
                                .market-item {
                                    flex: 0 1 auto;
                                    min-width: 160px;
                                    width: auto;
                                    margin: 0 25px 0 10px;
                                }

                                &:first-child {
                                    .market-item {
                                        &:first-child {
                                            margin-left: 0;
                                        }
                                    }
                                }
                                &:last-child {
                                    .market-item {
                                        &:first-child {
                                            margin-left: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .ant-row {
            .ant-col {
                .chart-tab {
                    .market-val {
                        .market-val-item {
                            flex: 1 1 auto;
                            max-width: 100%;
                            .market-item {
                                flex: 0 1 auto;
                                min-width: 160px;
                                width: auto;
                                margin: 0 25px 0 10px;
                            }

                            &:first-child {
                                .market-item {
                                    &:first-child {
                                        margin-left: 0;
                                    }
                                }
                            }
                            &:last-child {
                                .market-item {
                                    &:last-child {
                                        margin-left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .token-row {
        &.dash-row {
            .tokenholder-split {
                max-width: 100%;
            }
            .token-filter {
                display: none;
            }
        }
    }

}

@media (max-width: 990px) {

    .chart-row {
        .chart-innr {
            margin: 15px 0;
        }
        .chart-tab {
            &.nw-pop {
                
            }
        }
    }

}
@media (max-width: 880px) {


    .chart-row {
        .chart-tab {
            &.nw-pop {
                
            }
        }
        .chart-innr {
            canvas {
                background-size: 80%;
            }
        }
    }

}
@media (max-width: 768px) {
    .chart-row {
        .chart-tab {
            .market-val {
                flex-flow: column wrap;
            }
            &.nw-pop {
                
            }
        }
        .ant-row {
            .ant-col {
                .chart-tab {
                    .market-val {
                        .market-val-item {
                            .market-item {
                                flex: 1 1 auto;
                                min-width: auto;
                                max-width: 50%;
                                width: 100%;
                                margin: 0 0 10px 0;
                                &.full {
                                    max-width: 100%;
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
}
@media (max-width: 680px) {

    .chart-row {
        .chart-tab {
            &.nw-pop {
                
            }
        }
    }

}
@media (max-width: 580px) {

    .chart-txt {
        .chart-left {
            max-width: 100%;
            min-height: auto;
        }
    } 
    .chart-row {
        .chart-innr {
            padding: 20px;
            .chart-srtTxt {
                &.chart-scat {
                    padding: 0 0 0 13px;
                    .chart-horz {
                        left: -10px;
                    }
                }
            }
            
            &.main-chart {
                padding: 20px 7px 20px 15px;
                .chart-tab {
                    .chart-srt {
                        padding: 0 20px;
                    }
                }
                .chart-txt {
                    .chart-left {
                        padding: 0 13px;
                    }
                }
            }
            &.single-chart {
                padding: 20px;
            }
            .chart-srtTxt  {
                .chart-horz {
                    left: -30px;
                    margin: 0 0 0;
                }
                .chart-srt {
                    padding: 0 0 0 13px;
                }
            }
            .chart-srt {
                height: 100%;
                min-height: auto;
                > div {
                    > div {
                        min-height: 300px;
                        max-height: 300px;
                    }
                }
            }
        }
    }
    .chart-tab {
        &.nw-tab {
            .ant-tabs.ant-tabs-top {
                > .ant-tabs-nav {
                    position: relative;
                    top: 0;
                    right: 0;
                    background: transparent;
                    padding: 0;
                    border-radius: 0;
                    margin: 0 0 15px;
                    &::before {
                        display: none;
                    }
                    .ant-tabs-nav-wrap {
                        justify-content: center;
                        .ant-tabs-nav-list {
                            background: #eee;
                            padding: 5px 10px;
                            border-radius: 8px;
                        }
                    }
                }
            }
            .ant-tabs {
                .ant-tabs-ink-bar {
                    height: calc(100% - 7px);
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .chart-row {
        .chart-tab {
            .market-val {
                justify-content: center;
            }

            &.nw-pop {
                &.chart-tab {
                    .ant-tabs {
                        &.ant-tabs-top {
                            > .ant-tabs-nav {
                                margin: 15px 0 45px;
                            }
                        }
                    }
                }
                .chart-slt {
                    top: 170px;
                    right: inherit;
                    left: 50%;
                    transform: translateX(-50%);
                    .ant-select {
                        .ant-select-selector {
                            height: 32px;

                            .ant-select-selection-item {
                                padding: 0 10px 0 0;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        &.rev-row {
            .ant-row {
                .ant-col {
                    .chart-innr {
                        background-position: 90% 21%;

                        &.chart-dist {
                            background-position: 50% 50px;
                        }
                        &.main-chart {
                            background-position: 50% 11%;
                        }
                        &.chart-scatter {
                            background-position: 50% 50px;
                        }
                    }
                }
            }
        }
    }
    .ant-select-item-option-content {
        font-size: 12px;
    }
    .ant-tabs {
        > .ant-tabs-nav {
            .ant-tabs-nav-wrap {
                justify-content: center;
            }
        }
    }
    .chart-tab.nw-tab {
        .ant-tabs.ant-tabs-top {
            > .ant-tabs-nav {
                .ant-tabs-nav-wrap {
                    .ant-tabs-nav-list {
                        .ant-tabs-tab {
                            padding: 4px 13px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .chart-row {
        .chart-tab {
            .market-val {
                .market-item {
                    h6 {
                        font-size: 14px;
                        span {
                            &.mar-title {
                                display: flex;
                                flex-wrap: nowrap;
                                white-space: nowrap;
                                height: 100%;
                            }
                        }
                        &.flx {
                            display: flex;
                            flex-wrap: nowrap;
                            span {
                                &.market-txt {
                                    position: relative;
                                    padding: 0 0 0 5px;
                                    margin-left: 5px;
                                    width: auto;

                                    &::before {
                                        content: ":";
                                        position: absolute;
                                        top: 0;
                                        left: -5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.rev-row {
            .chart-innr {
                &.chart-scatter {
                    .chart-txt {
                        margin: 0 0 80px;
                    }
                }
            }
        }
    }
    .g2-tooltip {
        
        .chart-tooltip {
            .chart-val {
                flex-flow: column wrap;
                .chart-img {
                    max-width: 70px;
                }
                .chart-cnt {
                    max-width: 122px;
                }
            }
        }
    }

    .chart-tab {
        &.nw-dtab {
            .ant-tabs.ant-tabs-top {
                > .ant-tabs-nav {
                    .ant-tabs-nav-wrap {
                        .ant-tabs-nav-list {
                            .ant-tabs-tab {
                                padding: 2px 3px;
                                .ant-tabs-tab-btn {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

@media (max-width: 480px) {

    .g2-tooltip {
        .chart-tooltip {
            .chart-val {
                .chart-cnt {
                    max-width: 122px;
                }
            }
        }
    }
    .chart-row {
        &.rev-row {
            .ant-row {
                .ant-col {
                    .chart-innr {
                        background-position: 90% 165px;
                    }
                }
            }
        }
    }

}

@media (max-width: 420px) {
    .chart-txt {
        h4 {
            span {
                &.res {
                    display: block;
                }
            }
        }
    }
    .chart-row {
        .chart-tab {
            &.nw-pop {
                
            }
            &.nw-dtab {
                .ant-tabs {
                    &.ant-tabs-top {
                        > .ant-tabs-nav {
                            .ant-tabs-nav-wrap {
                                .ant-tabs-nav-list {
                                    padding: 5px 3px;

                                    .ant-tabs-tab {
                                        padding: 2px 1px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-select-item-option-content {
        font-size: 12px;
    }
    .ant-select-item {
        padding: 5px 5px;
    }
    
}  

@media (max-width: 365px) {

    .chart-tab {
        &.nw-dtab {
            &.nw-pop {
                
            }
        }
    }

}

/*Dev Code Start*/
.pt-10
{
    padding: 18px 0px 0px 7px;
}
.pt-7
{
    padding: 14px 0px 0px 5px;
}
.px-7
{
    padding-left: 7px;
}
/*Dev Code Start*/
@primary-color: #1DA57A;