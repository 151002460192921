.exp-collections{
    margin-top: 20px;
}

.tokenholders-head .title{
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 32px;

}

.tokenholders-head .font-bold {
    font-weight: 700;
}
    .menu-lists{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 40px;
    }
    .menu-lists .ant-select{
    width:140px !important;
    border-radius:10px;
    }
    .menu-lists h2{
    font-size: .75rem;
    line-height: 1rem;
    text-transform: uppercase;
    color:#1f2937;
    }
    .menu-lists .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    
    border-radius: 7px;
    }
.menu-lists .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 2px;
}
.table-tokens .ant-table-content{
    display:block !important;
}

.tokenholders-menu{
    font-size: 16px;
    line-height: 24px;
    padding-left: 16px;
    padding-right: 16px;
    --tw-bg-opacity: 1;
}

.tokenholders-menu a{
    color: #000;
    margin-right:13px
}


.tokenholders-menu > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-left:0px;
    margin-right:15px
}

.tokenholders-menu .next-link-exact-active{
    --tw-text-opacity: 1;
    color: rgba(213,63,232,var(--tw-text-opacity));
}

@media(max-width:1024px){
    .table-tokens{
		width: 100%;
    overflow-x: auto;
    }
}

@media(max-width:780px){
    .table-tokens{
        //display: none;
		width: 100%;
    overflow-x: auto;
    }
    // .table-tokens .ant-table-tbody > tr > td{

    //     padding:5px 10px
    // }
    // .ant-layout-content{
    
    // padding:50px 15px 30px !important;
    // }
    // .table-tokens .ant-table-tbody tr td:first-child{
    //     display:block;
    // }
    .menu-lists{
    justify-content: flex-start;
    
    }
    .mobile-sel{
   
    display: inline-block;
    margin-bottom: 20px;
    padding: 0 20px;
    }
    }
@media(max-width:400px){	
.menu-lists .ant-select{
    width: 120px !important;
}	
}

@media(max-width:320px){	
.menu-lists .ant-select{
    width: 140px !important;
}	
.menu-lists {
    justify-content: center;
}
}

.all-tokens-tbl{
    width:100%;
    overflow:auto
}


@primary-color: #1DA57A;